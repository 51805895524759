.SideDrawer {
	position: fixed;
	width: 280px;
	max-width: 70%;
	height: 100%;
	left: 0;
	top: 100px;
	z-index: 200;
	background-color: #fff;
	box-sizing: border-box;
	transition: transform .3s ease-out;
}

.SideDrawer nav {
	padding-left: 16px;
	padding-right: 16px;
}

.SideDrawer .NavigationItem a {
	padding: 8px 0;
}

@media (min-width: 768px) {
	.SideDrawer {
		display: none;
	}
}

.Open {
	transform: translateX(0);
}

.Close {
	transform: translateX(-100%);
}