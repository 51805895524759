.Logo {
	background-color: #a485cc;
	height: 100%;
	box-sizing: border-box;
	text-align: center;
}

.Logo a {
	height: 100%;
	display: flex;
	align-items: center;
	padding-top: 8px;
	padding-bottom: 8px;
}

.Logo img {
	max-height: 100%;
	max-width: 100%;
}