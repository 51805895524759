#privado-changepreferences {
	background-color: transparent;
	border: none;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}

#privado-changepreferences:hover {
	cursor: pointer;
	color: white;
}