.Anchor {
	position: relative;
}

.Anchor [id] {
	position: absolute;
	bottom: 262px;
}

@media (max-width: 767px) {
	.Anchor [id] {
		bottom: 100px;
	}
}