.SocialIcons {
	padding: 0 15px;
	display: flex;
	justify-content: space-between;
	gap: 15px;
	list-style: none;
	margin-top: 10px;
}

.SocialIcons li svg {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 1px solid black;
	transition: .3s all;
}

.SocialIcons li svg path {
	transition: .3s all;
}

@media (min-width: 768px) {
	.SocialIcons {
		position: absolute;
		bottom: 10px;
		right: 10px;
		padding: 0;
		margin: 0;
	}

	.SocialIcons li svg:hover {
		border: 3px solid white;
		width: 60px;
		height: 60px;
	}

	.SocialIcons li svg:hover path {
		fill: white;
	}

	.SocialIcons .Error {
		color: white;
		width: 360px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -100%;
	}

}

.SocialIcons .Spinner .loader {
	color: black;
	transform: scale(.5);
}

.SocialIcons .Spinner .loader::before,
.SocialIcons .Spinner .loader::after {
	background-color: #a485cc;
}
