.Shops {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.Shops .first-items {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.Shops .next-items {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Shops p {
	margin-top: 0;
}