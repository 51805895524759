.BlogEntry {
	padding: 20px;
	border-radius: 10px;
	border: 1px solid #a485cc;
	margin-bottom: 20px;
}

.BlogEntry .EntryDate {
	color: #a485cc;
	text-decoration: underline;
}