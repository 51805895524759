.Error {
	color: #a485cc;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Error .erroricon {
	border-radius: 100%;
	height: 26px;
	width: 26px;
	background-color: #a485cc;
	margin-right: 26px;
	border: 2px solid black;
	position: relative;
	transition: .3s background-color;
}

.Error .erroricon::before,
.Error .erroricon::after {
	background-color: white;
	border: 2px solid white;
	content: '';
	position: absolute;
	height: 0;
	left: 3px;
	top: 9px;
	width: 16px;
	transform: rotate(45deg);
}

.Error .erroricon::after {
	transform: rotate(-45deg);
}