.Toolbar {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	box-sizing: border-box;
	box-shadow: 0 0 5px;
	z-index: 90;
}

.Toolbar nav {
	height: 100%;
}

.LogoWrapper {
	height: 80%;
}

.UpperHeader,
.LowerHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.UpperHeader {
	position: absolute;
	background-color: #a485cc;
	height: 200px;
}

.LowerHeader {
	position: absolute;
	top: 200px;
	background-color: white;
	padding: 20px 0;
}

@media (max-width: 767px) {
	.UpperHeader {
		height: 100px;
		justify-content: space-evenly;
	}

	.LowerHeader {
		display: none;
	}

	.DesktopOnly {
		display: none;
	}
}

.DrawerToggle {
	background-color: #a485cc;
	border: none;
	border-radius: 0;
	color: white;
	outline: none;
	cursor: pointer;
	font: inherit;
	padding: 10px;
	margin: 10px 0;
	font-weight: bold;
	transition: .3s all;
	display: table;
	text-decoration: none;
	position: relative;
	padding: 20px;
	margin: 10px;
	cursor: pointer;
}

.DrawerToggle::before {
	display: none;
}

.DrawerToggle:hover,
.DrawerToggle:focus {
	background-color: transparent;
}

.DrawerToggle div {
	position: absolute;
	left: 0;
	width: 50px;
	height: 3px;
	background-color: white;
	transition: all .3s;
}

.DrawerToggle div:nth-child(1) {
	top: 0;
}

.DrawerToggle div:nth-child(2) {
	top: calc(50% - 1.5px);
}

.DrawerToggle div:nth-child(3) {
	bottom: 0;
}

.DrawerToggle.active div:nth-child(1) {
	transform: rotate(45deg);
	top: calc(50% - 1.5px);
}

.DrawerToggle.active div:nth-child(2) {
	opacity: 0;
}

.DrawerToggle.active div:nth-child(3) {
	transform: rotate(-45deg);
	bottom: calc(50% - 1.5px);
}

@media (min-width: 768px) {
	.DrawerToggle {
		display: none;
	}
}
