.Container {
	max-width: 960px;
	margin: 0 auto;
	padding: 20px;
}

.Container h1,
.Container h2,
.Container p,
.Container li,
.Container a {
	white-space: pre-wrap;
	word-wrap: break-word;
}