.Footer {
	background-color: #a485cc;
	display: flex;
	justify-content: space-between;
	gap: 20px;
	padding: 20px 0;
}

.Footer ul {
	display: flex;
	padding-right: 40px;
	margin: 0;
	gap: 20px;
}

.Footer a:hover,
.Footer a.active,
.Footer a:focus {
	color: white;
}

.Footer #privado-changepreferences {
	margin-left: 50px;
}

@media (max-width: 767px) {
	.Footer {
		justify-content: center;
		flex-wrap: wrap;
	}

	.Footer svg {
		display: none;
	}

	.Footer ul {
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
	}

	.Footer #privado-changepreferences {
		margin-left: 0;
	}

	.Footer .NavigationItem:last-child a {
		padding-right: 0;
	}
}

.Footer .Spinner {
	padding: 0;
	height: 54px;
	width: 54px;
	position: relative;
}

.Footer .Spinner .loader {
	color: black;
	transform: scale(.3);
	position: absolute;
	top: -50%;
	left: -50%;
}

.Footer .Spinner .loader::before,
.Footer .Spinner .loader::after {
	background-color: #a485cc;
}

.Footer .copyright {
	display: flex;
	align-items: center;
}