.NavigationItems {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow: column;
	align-items: center;
	height: 100%;
	gap: 20px;
}

@media (min-width: 768px) {
	.NavigationItems {
		flex-flow: row;
	}
}

.NavigationItems .Spinner {
	padding: 0;
	height: 54px;
	width: 54px;
	position: relative;
}

.NavigationItems .Spinner .loader {
	transform: scale(.3);
	position: absolute;
	top: -50%;
	left: -50%;
}