.ContentBox {
	padding: 15px;
}

@media (min-width: 769px) {
	.ContentBox {
		width: 50%;
	}
}

.ContentBox img {
	max-width: 100%;
	max-height: 650px;
	margin: 0 auto;
}