.Button {
	position: relative;
	display: inline-block;
	padding: 10px 20px;
	margin: 10px 0;
	background-color: #a485cc;
	color: white;
	text-decoration: none;
	font-size: 16px;
	line-height: normal;
	font-weight: bold;
	z-index: 1;
	border: none;
	outline: none;
	transition: background-color .3s, box-shadow .3s, transform .3s;
	overflow: hidden;
	border-radius: 40px;
	box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.75);
	border: 1px solid #603c8f;
}

.Button::before {
	content: '';
	position: absolute;
	top: -40%;
	right: 110%;
	width: 30px;
	height: 200%;
	background: rgba(255, 255, 255, .3);
	transform: rotate(20deg);
	transition: right .5s ease;
	pointer-events: none;
}

.Button:hover,
.Button:focus {
	cursor: pointer;
	background-color: #603c8f;
	color: white;
	box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
	transform: scale(110%);

	&::before {
		right: -50%;
	}
}

.Button:active {
	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.75);
	transform: scale(90%);
}

.Button.center {
	margin: 20px auto;
}
