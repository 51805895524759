.Spinner {
	width: 100%;
	max-width: 250px;
	margin: auto;
}

.Spinner svg {
	max-width: 100%;
	display: block;
	shape-rendering: auto;
}