html {
	width: 100%;
	height: 100%;
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
}

::selection {
	background: #a485cc;
	color: #fff;
}

a { color: #a485cc; text-decoration: none; transition: color .3s; }
a:hover { color: #000; }

*,
*::before,
*::after {
	box-sizing: border-box;
}

:focus {
	outline: none;
}

h1 {
	margin: 0;
	padding: .67em 0;
}

.text-center {
	text-align: center;
}

.block {
	display: block;
}

img {
	display: block;
	max-width: 100%;
}

.grecaptcha-badge {
    bottom: 54px !important;
}