.Modal {
	position:fixed;
	display:table;
	height:100%;
	width:100%;
	top:0;
	left:0;
	transform:scale(0);
	z-index:1000;
}

.Modal h2 {
	font-size:25px;
	line-height:25px;
	margin-bottom:15px;
}

.Modal p {
	font-size:18px;
	line-height:22px;
}

.Modal .content {
	background-color: #a485cc;
	border: 1px solid #ccc;
	box-shadow: 1px 1px 1px black;
	color: white;
	padding:50px;
	display:inline-block;
	border-radius:3px;
	font-weight:300;
	position: absolute;
	top: 50%;
	left: 50%;
	transition: opacity .3s, transform .3s;
	z-index: 4;
}

.Modal .content ::selection {
	background: #603c8f;
}

.Modal .content .modal-close {
	background: none;
	border: none;
	color: white;
	position: absolute;
	top: 25px;
	right: 25px;
	height: 16px;
	width: 16px;
	transition: .3s background-color;
}

.Modal .content .modal-close::before,
.Modal .content .modal-close::after {
	background-color: white;
	border: 2px solid white;
	content: '';
	position: absolute;
	height: 0;
	left: 0;
	top: 50%;
	width: 16px;
	transform: translateY(-50%) rotate(45deg);
	transition: .3s background-color, .3s border-color;
}

.Modal .content .modal-close::after {
	transform: translateY(-50%) rotate(-45deg);
}

.Modal .content .modal-close:hover {
	cursor: pointer;
}

.Modal .content .modal-close:hover::before,
.Modal .content .modal-close:hover::after {
	background-color: black;
	border-color: black;
}

.Modal .content .Error {
	color: white;
}

.Modal .Form .form-group:not(.checkbox):not(.textarea) input:focus + label,
.Modal .Form .form-group.checkbox label a:hover {
	color: #603c8f;
}

.Modal .Form .form-group.checkbox label:hover input ~ .checkmark,
.Modal .Form .form-group.checkbox label input:checked ~ .checkmark {
	background-color: #603c8f;
}

.Modal .Form {
	text-align: center;
}

.Modal .Form .form-group.checkbox label a {
	color: black;
}

.Modal.active {
	transform:scale(1);
}

.Modal.active + .Container {
	animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.Modal.active.out {
	animation: quickScaleDown 0s .5s linear forwards;
}

.Modal.active.out + .Container {
	animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.Modal.active.out .Backdrop {
	animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.Modal.active.out .content {
	opacity: 0;
	transform: translate(-50%, 200%) scale(0);
}

.Modal.active .Backdrop {
	background:rgba(0,0,0,.0);
	animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.Modal.active .content {
	opacity: 1;
	transform: translate(-50%, -50%) scale(1);
}

@keyframes fadeIn {
	0% {
		background:rgba(0,0,0,.0);
	}
	100% {
		background:rgba(0,0,0,.7);
	}
}

@keyframes fadeOut {
	0% {
		background:rgba(0,0,0,.7);
	}
	100% {
		background:rgba(0,0,0,.0);
	}
}

@keyframes scaleUp {
	0% {
		transform:scale(.8) translateY(1000px);
		opacity:0;
	}
	100% {
		transform:scale(1) translateY(0px);
		opacity:1;
	}
}

@keyframes scaleDown {
	0% {
		transform:scale(1) translateY(0px);
		opacity:1;
	}
	100% {
		transform:scale(.8) translateY(1000px);
		opacity:0;
	}
}

@keyframes scaleBack {
	0% {
		transform:scale(1);
	}
	100% {
		transform:scale(.85);
	}
}

@keyframes scaleForward {
	0% {
		transform:scale(.85);
	}
	100% {
		transform:scale(1);
	}
}

@keyframes quickScaleDown {
	0% {
		transform:scale(1);
	}
	99.9% {
		transform:scale(1);
	}
	100% {
		transform:scale(0);
	}
}
