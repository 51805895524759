.NavigationItem {
	margin: 10px 0;
	box-sizing: border-box;
	display: block;
}

.NavigationItem a {
	color: black;
	text-decoration: none;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.NavigationItem svg {
	width: 14px;
	height: 14px;
}

.NavigationItem a:focus svg path,
.NavigationItem a.active svg path {
	fill: #a485cc;
}

.NavigationItem a:hover,
.NavigationItem a:focus,
.NavigationItem a:active,
.NavigationItem a.active {
	color: #a485cc;
}

@media (min-width: 768px) {
	.NavigationItem {
		margin: 0;
		display: flex;
		height: 100%;
		width: auto;
		align-items: center;
	}

	.NavigationItem a {
		color: black;
		height: 100%;
	}

	.NavigationItem a:hover,
	.NavigationItem a:focus,
	.NavigationItem a:active,
	.NavigationItem a.active {
		color: #a485cc;
	}

	.NavigationItem svg {
		display: none;
	}
}