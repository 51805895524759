.Form.cols-2 .form-group {
	width: 50%;
	display: inline-block;
	padding-right: 20px;
}

.Form .form-group:not(.checkbox):not(.textarea) {
	position: relative;
	margin: 15px auto;
}

.Form .form-group:not(.checkbox):not(.textarea) label {
	position: absolute;
	top: 0;
	left: 0;
	padding: .18rem 0 0;
	transition: all .3s;
	font-size: 1rem;
}

.Form .form-group:not(.checkbox):not(.textarea) input {
	border: none;
	border-bottom: 1px solid black;
	margin: 10px 0;
	background-color: transparent;
	width: 100%;
}

.Form .form-group:not(.checkbox):not(.textarea) input:focus + label,
.Form .form-group:not(.checkbox):not(.textarea) input:not(:placeholder-shown) + label {
	font-size: 70%;
	color: #a485cc;
	transform: translate3d(0, -100%, 0);
	padding: 0;
}

.Form .form-group.checkbox {
	max-width: 500px;
	margin: 0 auto;
}

.Form .form-group.checkbox label {
	position: relative;
	display: block;
	padding-left: 35px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.Form .form-group.checkbox label input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 1px;
	width: 1px;
	margin: 0;
	top: 24px;
	left: 12px;
}

/* Create a custom checkbox */
.Form .form-group.checkbox .checkmark {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
	transition: all .3s;
}

/* On mouse-over, add a grey background color */
.Form .form-group.checkbox label:hover input ~ .checkmark {
	background-color: #a485cc;
}

/* When the checkbox is checked, add a blue background */
.Form .form-group.checkbox label input:checked ~ .checkmark {
	background-color: #a485cc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.Form .form-group.checkbox label input:checked ~ .checkmark::after {
	display: block;
}

/* Style the checkmark/indicator */
.Form .form-group.checkbox label .checkmark::after {
	left: 9px;
	top: 4px;
	width: 8px;
	height: 14px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.Form .form-group.textarea {
	position: relative;
}

.Form .form-group.textarea textarea {
	width: 100%;
	height: 100px;
	border: 1px solid black;
	border-radius: 10px;
	padding: 10px;
	margin-top: 23px;
}

.Form .form-group.textarea label {
	position: absolute;
	top: 0;
	left: 0;
}

.Form .Loader::before,
.Form .Loader::after {
	background: #cccccc;
}