.BookCard {
	padding: 15px;
}

.BookCard > a {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	text-align: center;
	color: black;
	height: 100%;
	padding: 15px;
	background-color: #ece4f5;
	transition: transform .3s, box-shadow .3s;
}

.BookCard > a:hover {
	transform: translate(2px, -2px);
	box-shadow: -10px 10px 5px 0px rgba(0,0,0,0.5);
}

.BookCard h2 {
	margin-bottom: 0;
}

@media (min-width: 769px) {
	.BookCard:not(.full) {
		width: 50%;
	}
}